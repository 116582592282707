var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.notificationShown
      ? _c("div", { staticClass: "w-full" }, [
          _c("div", {
            staticClass: "fixed top-0 w-full h-20 z-40 bg-gray-900 opacity-50"
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "fixed top-0 left-0 right-0 container sm:max-w-xl md:max-w-3xl flex flex-row justify-between items-center border-l-2 bg-white text-lg font-medium rounded container mx-auto my-2 py-4 pl-4 pr-8 shadow-lg z-50",
              class: [
                _vm.messageType === "success"
                  ? "border-green-400 text-green-800"
                  : "border-red-400 text-red-800"
              ]
            },
            [
              _c(
                "div",
                { staticClass: "flex items-center" },
                [
                  _vm.messageType === "success"
                    ? _c("font-awesome-icon", {
                        staticClass: "cursor-pointer text-2xl text-green-400",
                        attrs: { icon: _vm.faCheckCircle }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.messageType === "error"
                    ? _c("font-awesome-icon", {
                        staticClass: "cursor-pointer text-2xl text-red-400",
                        attrs: { icon: _vm.faExclamationCircle }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "inline pl-2" }, [
                    _vm._v(
                      "\r\n          " + _vm._s(_vm.message) + " \r\n        "
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "inline",
                  on: { click: _vm.closeNotificationPopup }
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "cursor-pointer text-sm",
                    attrs: { icon: _vm.faTimes }
                  })
                ],
                1
              )
            ]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }