var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading || _vm.localLoadingState
    ? _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "h-screen w-screen fixed inset-0 bg-gray-900 opacity-0 z-50"
        })
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "fixed h-screen w-screen z-60 flex flex-row items-center justify-center"
      },
      [
        _c("div", { staticClass: "bg-transparance p-8 rounded-lg" }, [
          _c("div", {
            staticClass:
              "circle w-32 h-32 flex flex-row justify-between items-center"
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }