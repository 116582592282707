<template>
<div class="sticky top-0 z-40">
  <div class="h-1" style="background: linear-gradient(to right, #4b44b9, #8497EC);"></div>
  <!-- <nav class="bg-blue-910 h-12 border-b"> -->
  <nav :style="{ backgroundColor: themeColor }" class="h-12 border-b">
    <div class="px-4 flex flex-row justify-between h-full">
      <a class="flex items-center text-white text-2xl font-bold md:font-normal no-underline h-12" :href="url.home">
        <img :src="generateUrl(logoPreview, '/image/favpng.jpg')" alt="" class="h-full py-1 block mr-2">
        {{ companyName ? companyName : "PIIS" }}
      </a>
      <div class="md:flex">
        <div class="flex flex-row h-full">
          <notification-dropdown></notification-dropdown>
          <profile-dropdown></profile-dropdown>
        </div>
      </div>
    </div>
  </nav>
</div>
</template>

<script>
  import notificationDropdown from './notificationDropdown'
  import profileDropdown from './profileDropdown'
  import { faBars, faBraille } from '@fortawesome/free-solid-svg-icons'
  export default {
    components: {
      notificationDropdown, 
      profileDropdown
    },
    data: () => ({
      user: user,
      token: Laravel.csrfToken,
      url: navUrl,
      faBars,
      faBraille,
      companyName: 'PIIS',
      logoPreview: '',
      themeColor: "#026aa7", // "#0e3b95" // Default color
    }),
    created () {
      axios.get('/site-setting')
      .then(response => {
        const siteSettings = response.data.siteSetting;
        this.companyName = siteSettings.company_name;
        this.colors = siteSettings.colors;

        this.logoPreview = "/storage/" + siteSettings.logo_img;
        if(!siteSettings.logo_img_exists) {
          this.logoPreview = 'logos/logo_default.png';
        }
      })
      .catch(error => {
        // Handle the error here
      });

      const savedColor = localStorage.getItem("themeColor");
      if (savedColor) {
        this.themeColor = savedColor;
      }
      
      // Tambahkan event listener untuk mendengarkan perubahan warna
      window.addEventListener("themeColorChanged", this.updateThemeColor);
    },
    beforeUnmount() {
      // Hapus event listener saat komponen di-unmount
      window.removeEventListener("themeColorChanged", this.updateThemeColor);
    },
    methods: {
      updateThemeColor() {
        this.themeColor = localStorage.getItem("themeColor") || "#026aa7";
      }
    }
  }
</script>
