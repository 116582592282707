const terbilang = (angka)=>{
    var bilne=["","satu","dua","tiga","empat","lima","enam","tujuh","delapan","sembilan","sepuluh","sebelas"];

		if(angka < 12){

			return bilne[angka];

		}else if(angka < 20){

			return terbilang(angka-10)+" belas";

		}else if(angka < 100){

			return terbilang(Math.floor(parseInt(angka)/10))+" puluh "+terbilang(parseInt(angka)%10);

		}else if(angka < 200){

			return "seratus "+terbilang(parseInt(angka)-100);

		}else if(angka < 1000){

			return terbilang(Math.floor(parseInt(angka)/100))+" ratus "+terbilang(parseInt(angka)%100);

		}else if(angka < 2000){

			return "seribu "+terbilang(parseInt(angka)-1000);

		}else if(angka < 1000000){

			return terbilang(Math.floor(parseInt(angka)/1000))+" ribu "+terbilang(parseInt(angka)%1000);

		}else if(angka < 1000000000){

			return terbilang(Math.floor(parseInt(angka)/1000000))+" juta "+terbilang(parseInt(angka)%1000000);

		}else if(angka < 1000000000000){

			return terbilang(Math.floor(parseInt(angka)/1000000000))+" milyar "+terbilang(parseInt(angka)%1000000000);

		}else if(angka < 1000000000000000){

			return terbilang(Math.floor(parseInt(angka)/1000000000000))+" trilyun "+terbilang(parseInt(angka)%1000000000000);
        }
}

const satuan = (stn) =>{
	switch (stn) {
		case 'IDR':
			return 'Rupiah';
			break;
		case 'SGD':
			return 'Dolar Singapura';
			break;
		case 'USD':
			return 'US Dolar';
			break;
		case 'EUR':
			return 'Euro'
		default:
			return '';
			break;
	}
} 

const capitalize = (str) =>{
	let arr = str.toString();
	arr = arr.split(' ');
	let val = '';
	arr.forEach((el,index,ar) => {
		if(el){
			if(index == arr.length-1){
				val += el[0].toUpperCase() + el.slice(1).toLowerCase();
				}else{
				val += el[0].toUpperCase() + el.slice(1).toLowerCase() + ' ';
			}
		}
	});
	return val;
}

module.exports = { terbilang, satuan, capitalize }