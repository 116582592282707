var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideNotification,
          expression: "hideNotification"
        }
      ],
      staticClass: "self-center relative"
    },
    [
      _c(
        "div",
        {
          staticClass: "text-white text-base no-underline cursor-pointer",
          attrs: { id: "notification" }
        },
        [
          _c(
            "button",
            {
              staticClass:
                "flex items-center p-2 font-medium mr-2 text-center bg-orange-600 rounded hover:bg-orange-500 focus:outline-none",
              on: { click: _vm.toggleNotification }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "font-bold text-xl",
                attrs: { icon: _vm.faBell }
              }),
              _vm._v(" "),
              _vm.unreadNotification
                ? _c("font-awesome-icon", {
                    staticClass: "text-white text-xs absolute -mt-4 -ml-2",
                    attrs: { icon: _vm.faCircle, "aria-hidden": "true" }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _vm.currentComponent === "notification-dropdown"
        ? _c(
            "div",
            {
              staticClass:
                "absolute bg-white w-72 flex flex-col mt-5 -mr-16 right-0 shadow-lg rounded z-50"
            },
            [
              _vm.notifications.length > 0
                ? _c(
                    "div",
                    { staticClass: "h-128 overflow-y-auto" },
                    _vm._l(_vm.notifications, function(notification, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "flex flex-row items-center px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer border-b",
                          attrs: { href: "#" }
                        },
                        [
                          _c("div", { staticClass: "w-16 self-start my-2" }, [
                            _c("img", {
                              staticClass: "w-10 h-10 rounded-full mr-2",
                              attrs: {
                                src: _vm.generateUrl(
                                  notification.data.subject.avatar
                                )
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "w-56" }, [
                            _c("div", { staticClass: "py-1 text-sm" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "no-underline hover:underline text-blue-400",
                                  attrs: {
                                    href:
                                      "/users/" +
                                      notification.data.subject.username
                                  }
                                },
                                [_vm._v(_vm._s(notification.data.subject.name))]
                              ),
                              _vm._v(
                                "\r\n            " +
                                  _vm._s(notification.data.action) +
                                  "\r\n            "
                              ),
                              notification.data.url
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "no-underline hover:underline text-blue-400",
                                      attrs: { href: notification.data.url }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(notification.data.object_name)
                                      )
                                    ]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "no-underline text-blue-400"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(notification.data.object_name)
                                      )
                                    ]
                                  ),
                              _vm._v(
                                "\r\n            " +
                                  _vm._s(notification.data.task_status) +
                                  "\r\n          "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "py-1 text-xs" }, [
                              _vm._v(
                                "\r\n            " +
                                  _vm._s(notification.date) +
                                  "\r\n          "
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.notifications.length === 0
                ? _c(
                    "div",
                    { staticClass: "px-4 py-2 text-sm text-gray-600 block" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm._f("localize")(
                            "No unread notifications. You're all caught up"
                          )
                        )
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "block border-t" }),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "list-reset w-full hover:bg-blue-910 hover:text-white px-4 py-2 text-blue-400 text-sm text-center no-underline block cursor-pointer",
                  on: {
                    click: function($event) {
                      return _vm.showNotificationBox()
                    }
                  }
                },
                [
                  _vm._v(
                    "\r\n      " +
                      _vm._s(_vm._f("localize")("View All")) +
                      "\r\n    "
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }