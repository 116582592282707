var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sticky top-0 z-40" }, [
    _c("div", {
      staticClass: "h-1",
      staticStyle: { background: "linear-gradient(to right, #4b44b9, #8497EC)" }
    }),
    _vm._v(" "),
    _c(
      "nav",
      {
        staticClass: "h-12 border-b",
        style: { backgroundColor: _vm.themeColor }
      },
      [
        _c(
          "div",
          { staticClass: "px-4 flex flex-row justify-between h-full" },
          [
            _c(
              "a",
              {
                staticClass:
                  "flex items-center text-white text-2xl font-bold md:font-normal no-underline h-12",
                attrs: { href: _vm.url.home }
              },
              [
                _c("img", {
                  staticClass: "h-full py-1 block mr-2",
                  attrs: {
                    src: _vm.generateUrl(_vm.logoPreview, "/image/favpng.jpg"),
                    alt: ""
                  }
                }),
                _vm._v(
                  "\r\n        " +
                    _vm._s(_vm.companyName ? _vm.companyName : "PIIS") +
                    "\r\n      "
                )
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "md:flex" }, [
              _c(
                "div",
                { staticClass: "flex flex-row h-full" },
                [
                  _c("notification-dropdown"),
                  _vm._v(" "),
                  _c("profile-dropdown")
                ],
                1
              )
            ])
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }