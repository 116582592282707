var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.currentComponent === "notification-box"
    ? _c("div", [
        _c(
          "div",
          {
            staticClass:
              "fixed top-0 z-40 inset-x-0 mx-auto sm:max-w-xl md:max-w-3xl lg:max-w-5xl xl:max-w-6xl mt-16 md:mt-24 px-4"
          },
          [
            _c("div", { staticClass: "bg-gray-100 rounded shadow-lg" }, [
              _c(
                "div",
                { staticClass: "p-4 flex justify-between items-center" },
                [
                  _c("div"),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-lg" }, [
                    _vm._v("\r\n          Notifications\r\n        ")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "cursor-pointer",
                      on: {
                        click: function($event) {
                          return _vm.hideNotificationBox()
                        }
                      }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "items-center text-gray-600 mr-1",
                        attrs: { icon: _vm.faTimes }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.notifications.length > 0
                ? _c(
                    "div",
                    { staticClass: "h-80-vh overflow-y-auto bg-white" },
                    _vm._l(_vm.notifications, function(notification) {
                      return _c(
                        "div",
                        {
                          staticClass:
                            "flex flex-row items-center px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer border-t",
                          attrs: { href: "#" }
                        },
                        [
                          _c("div", { staticClass: "w-16 self-start my-2" }, [
                            _c("img", {
                              staticClass: "w-10 h-10 rounded-full mr-2",
                              attrs: {
                                src: _vm.generateUrl(
                                  notification.data.subject.avatar
                                )
                              }
                            })
                          ]),
                          _vm._v(" "),
                          _c("div", {}, [
                            _c("div", { staticClass: "py-1 text-sm" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "no-underline hover:underline text-blue-400",
                                  attrs: {
                                    href:
                                      "/users/" +
                                      notification.data.subject.username
                                  }
                                },
                                [_vm._v(_vm._s(notification.data.subject.name))]
                              ),
                              _vm._v(
                                "\r\n              " +
                                  _vm._s(notification.data.action) +
                                  "\r\n              "
                              ),
                              notification.data.url
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "no-underline hover:underline text-blue-400",
                                      attrs: { href: notification.data.url }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(notification.data.object_name)
                                      )
                                    ]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "no-underline text-blue-400"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(notification.data.object_name)
                                      )
                                    ]
                                  ),
                              _vm._v(
                                "\r\n              " +
                                  _vm._s(notification.data.task_status) +
                                  "\r\n            "
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "py-1 text-xs" }, [
                              _vm._v(
                                "\r\n              " +
                                  _vm._s(notification.date) +
                                  "\r\n            "
                              )
                            ])
                          ])
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "rounded-b bg-white h-1" })
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass:
            "h-screen w-screen fixed inset-0 bg-gray-900 opacity-25 z-30",
          on: {
            click: function($event) {
              return _vm.hideNotificationBox()
            }
          }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }