import Echo from 'laravel-echo'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ClickOutside from 'vue-click-outside'
import * as linkify from 'linkifyjs'
import linkifyElement from 'linkifyjs/element'
import mention from 'linkifyjs/plugins/mention'
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import VueCarousel from "vue-carousel";
import { terbilang, satuan, capitalize } from './helper/terbilang';
import "vue-easytable/libs/theme-default/index.css"; // import style
import VueEasytable from "vue-easytable"; // import library

// import { VeLocale } from "vue-easytable";
// import language
// import enUS from "vue-easytable/libs/locale/lang/en-US.js";
// VeLocale.use(enUS);


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'


// install rules and localization
Object.keys(rules).forEach(rule => {
  // extend(rule, rules[rule]);
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

// custom validation
// Decimal Validation
extend('float',{
  validate(value,args){
      let valid = (value.match(/^-?\d*(\.\d+)?$/));
      if(args.separator == 'comma'){
          valid = (value.match(/^-?\d*(,\d+)?$/));
      }
      return valid
  },
  params:['separator']
});

// import { ValidationProvider, extend } from 'vee-validate';
// import { required } from 'vee-validate/dist/rules';
// extend('required', {
//   ...required,
  // message: 'This field is required'
  // message: 'The {_field_} field is required'
// });


mention(linkify)
window.Vue = require('vue')
window.axios = require('axios')
window.luxon = require('luxon')
window._ = require('lodash')
// window.io = require('socket.io-client');
window.axios.defaults.headers.common = {
  'X-CSRF-TOKEN': window.Laravel.csrfToken,
  'X-Requested-With': 'XMLHttpRequest'
}
// console.log(window.io);
if (typeof io !== 'undefined') {
  window.Echo = new Echo({
    broadcaster: 'socket.io',
    host: window.location.hostname + ':6001',
    namespace: 'App.Base.Events',
    // auth:{
    //   headers:{
    //     'X-CSRF-TOKEN': window.Laravel.csrfToken,
    //   }
    // },
    csrfToken: window.Laravel.csrfToken,
    // client:require('socket.io-client')
  })
}
window.Vue.mixin({
  methods: {
    hasActionPermissions: function (user, action, resource) {
      let result = null
      result = user.role.permissions.find(permission => permission.action === action && permission.resource === resource)
      return result
    },
    generateUrl: function (value, img = '/image/avatar.jpg') {
      if (!value) return window.location.protocol + '//' + window.location.host + img
      return window.location.protocol + '//' + window.location.host + '/' + value.toString()
    },
    updateUrl: function (params) {
      const url = new URL(window.location.href)
      for (const key in params) {
        if (url.searchParams.has(key)) {
          url.searchParams.delete(key)
        }
        if (params[key] !== null) {
          url.searchParams.append(key, params[key])
        }
      }
      window.history.pushState({ path: url.href }, '', url.href)
    },
    containerList (data) {
      if (data.container_list) {
        if(data.container_list.length > 0) {
          let map = data.container_list.map(function(row) {
            return row.container['no_cont']
          });
          return map.join(', ')
        } 
        return 'Tidak ada'
      }
    },   
    checkVal(arr, val) {
      return arr.some(value => value === val);
    }
  }
})

window.Vue.filter('localize', function (value) {
  if (!value) return ''
  value = value.toString()
  return window.lang[value] ? window.lang[value] : value
})

window.Vue.prototype.trans = (key) => {
  return _.get(window.lang, key, key);
}

window.Vue.filter('uppercase', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

window.Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

window.Vue.filter('clip', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.substr(0, 20) + '...'
})

window.Vue.filter('formatPrice', function(value){
  let val = (value/1).toFixed(2).replace('.', ',')
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

window.Vue.filter('undoFormatPrice', function(value){
  return value.replace(/\.|,|[^,]*$/g, "");
})

window.Vue.filter('numToWord',function(value){
  if (!value) return 'nol'
  value = terbilang(value);
  return value;
})

window.Vue.filter('capEachWord',function(value){
  if(!value) return ''
  value = capitalize(value);
  return value;
})

window.Vue.filter('currency', function(value){
  value = satuan(value);
  return value;
})

window.Vue.directive('linkify', {
  inserted: function (el) {
    linkifyElement(el, {
      className: 'text-blue-500',
      formatHref: function (href, type) {
        if (type === 'mention') {
          return window.location.origin + '/users' + href
        }
        return href
      }
    })
  }
})

window.Vue.directive('click-outside', ClickOutside)
window.Vue.component('font-awesome-icon', FontAwesomeIcon)
window.Vue.component('v-select', vSelect)
window.Vue.component('ValidationObserver', ValidationObserver);
window.Vue.component('ValidationProvider', ValidationProvider);
window.Vue.use(VuejsDialog, {
  okText: 'Ya',
  cancelText: 'Tidak'
})
window.Vue.use(VueGoodTablePlugin);
window.Vue.use(VueEasytable);
window.EventBus = new Vue()
window.Vue.use(VueCarousel);
// window.Vue.use(ElementUI);
window.Vue.use(ElementUI, { locale })