var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hideProfileDropdown,
          expression: "hideProfileDropdown"
        }
      ],
      staticClass: "self-center relative"
    },
    [
      _c(
        "div",
        {
          staticClass: "text-white text-base no-underline cursor-pointer",
          attrs: { id: "profile-dropdown" },
          on: { click: _vm.toggleProfileDropdown }
        },
        [
          _c("div", { staticClass: "flex flex-row items-center" }, [
            _c("img", {
              staticClass: "w-8 h-8 rounded-full",
              staticStyle: { "margin-right": "0rem" },
              attrs: { src: _vm.generateUrl(_vm.user.avatar) }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "pl-2" }, [
              _vm._v(
                "\r\n        Hi, " +
                  _vm._s(_vm.user.username.substring(0, 25)) +
                  "\r\n        "
              )
            ])
          ])
        ]
      ),
      _vm._v(" "),
      _vm.currentComponent === "profile-dropdown"
        ? _c(
            "div",
            {
              staticClass:
                "absolute bg-white w-72 flex flex-col mt-5 right-0 shadow-lg rounded z-50"
            },
            [
              _c(
                "a",
                {
                  staticClass:
                    "px-4 py-2 hover:bg-blue-910 hover:text-white no-underline text-gray-600 block font-medium",
                  attrs: { href: _vm.profileUrl }
                },
                [
                  _c(
                    "span",
                    { staticClass: "w-6 inline-block" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pr-1",
                        attrs: { icon: _vm.faUser }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\r\n      " +
                      _vm._s(_vm._f("localize")("Your Profile")) +
                      "      \r\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "px-4 py-2 hover:bg-blue-910 hover:text-white text-gray-600 font-medium no-underline block",
                  attrs: { href: "/admin" }
                },
                [
                  _c(
                    "span",
                    { staticClass: "w-6 inline-block" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pr-1 font-regular",
                        attrs: { icon: _vm.faCog }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\r\n      " +
                      _vm._s(_vm._f("localize")("Admin")) +
                      "\r\n    "
                  )
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "block border-t" }),
              _vm._v(" "),
              _vm.impersonating
                ? _c(
                    "a",
                    {
                      staticClass:
                        "px-4 py-2 hover:bg-blue-910 hover:text-white text-gray-600 font-medium no-underline block",
                      attrs: { href: "/impersonate/leave" }
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "w-6 inline-block" },
                        [
                          _c("font-awesome-icon", {
                            staticClass: "pr-1 font-regular",
                            attrs: { icon: _vm.faUserMinus }
                          })
                        ],
                        1
                      ),
                      _vm._v(
                        "\r\n      " +
                          _vm._s(_vm._f("localize")("Leave User")) +
                          "\r\n    "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass:
                    "px-4 py-2 hover:bg-blue-910 hover:text-white text-gray-600 font-medium no-underline block",
                  attrs: { href: _vm.url.logout },
                  on: { click: _vm.logoutUser }
                },
                [
                  _c(
                    "span",
                    { staticClass: "w-6 inline-block" },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "pr-1 font-regular",
                        attrs: { icon: _vm.faSignOutAlt }
                      })
                    ],
                    1
                  ),
                  _vm._v(
                    "\r\n      " +
                      _vm._s(_vm._f("localize")("Logout")) +
                      "\r\n    "
                  )
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "form",
        {
          staticStyle: { display: "none" },
          attrs: { id: "logout-form", action: _vm.url.logout, method: "POST" }
        },
        [
          _c("input", {
            attrs: { type: "hidden", name: "_token" },
            domProps: { value: _vm.token }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }